export default {
  sections: () => {
    return [
      {
        editable: false,
        title: 'Общие сведения',
        params: [
          {
            title: 'АДРЕС',
            key: 'address'
          },
          {
            title: 'ВИД НТО',
            key: 'object_type'
          },
          {
            title: 'СПЕЦИАЛИЗАЦИЯ НТО ПО 966-П',
            key: 'object_specialization'
          },
          {
            title: 'ОБЩАЯ ПЛОЩАДЬ, КВ.М.',
            key: 'square'
          },
          {
            title: 'НОМЕР ДОГОВОРА',
            key: 'dogovor_num'
          },
          {
            title: 'ДАТА НАЧАЛА',
            key: 'date_start'
          },
          {
            title: 'ДАТА ОКОНЧАНИЯ',
            key: 'date_end'
          }
        ]
      }
    ];
  }
};
